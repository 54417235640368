<template>
  <v-container :class="`${$vuetify.breakpoint.lgAndUp ? 'px-6' : 'px-4'} py-6`">
    <!-- CARD DO CLIENTE -->
    <v-card
      :class="`fill-height mx-auto elevation-0 d-flex flex-column`"
      outlined
      width="100%"
      height="88vh"
    >
      <!-- HEADER -->
      <v-row
        fluid
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
        class="px-5 py-2 ma-0 d-flex align-center"
      >
        <v-col class="d-flex align-center ma-0 pa-0" sm="8" md="6">
          <v-btn
            @click="goBack"
            :class="`${isMobile ? 'mr-2 ml-0' : ''} breadcrumb-link`"
            icon
            :small="isMobile"
          >
            <v-icon v-text="'mdi-arrow-left'" />
          </v-btn>

          <v-row class="ma-0 pa-0" width="250" v-if="loading">
            <v-col
              class="ma-0 pa-0 d-flex justify-start"
              v-for="i in 2"
              :key="i"
              md="2"
            >
              <v-skeleton-loader type="chip"></v-skeleton-loader>
              <v-icon v-if="i < 2" v-text="'mdi-chevron-right'" />
            </v-col>
          </v-row>

          <v-breadcrumbs
            v-else-if="!loading && !isMobile"
            :items="breadcrumbs"
            class="pa-0 ml-3 mr-2 breadcrumbs breadcrumb-link"
            large
          >
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>

          <MenuClientSearch
            v-if="!loading"
            :client-name="clientName"
            :all-clients="allClients"
            @reload="reloadMoreInfoPlans()"
            :small="isMobile"
          />
        </v-col>

        <v-col class="ma-0 pa-0 d-flex" sm="4" md="6">
          <v-row class="ma-0 pa-0" width="250" v-if="loading && !isMobile">
            <v-col class="ma-0 pa-0 d-flex ml-auto" md="1">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
          <!-- SIMULAR LOGIN CONECTA CONTROL E CONECTA SUITE -->
          <v-menu bottom offset-y right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                text
                data-test-id="cy-simulate-login-btn"
                color="accent"
                :class="`${
                  isMobile ? 'my-1 py-4' : 'mt-1'
                } ml-auto mt-1 text-none text-body-2 font-weight-medium text--accent mr-0 pl-3 pr-4 mb-2`"
                right
                :disabled="loading || loadingSimulation || isSimulation"
                :loading="loadingSimulation"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  :class="!isMobile ? 'mr-2' : ''"
                  v-text="'mdi-account-supervisor'"
                />
                {{ isMobile ? "" : "Simular login do usuário" }}
                <v-icon dark right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-subheader
                class="text-uppercase px-4 py-1 font-weight-medium caption"
              >
                Conecta Suite
              </v-subheader>
              <v-list-item
                @click="simulateLoginSuite()"
                :disabled="!selectedClient.users_number"
              >
                <v-list-item-title> Simular login no Suite </v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-subheader
                class="text-uppercase px-4 mt-2 py-1 font-weight-medium caption"
              >
                Conecta Control
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="grey"
                      small
                      right
                      v-bind="attrs"
                      v-on="on"
                      v-text="'mdi-help-circle'"
                    />
                  </template>
                  <span
                    >É preciso já estar logado no Control para ser direcionado
                    corretamente!</span
                  >
                </v-tooltip>
              </v-subheader>
              <!-- SIMULAR LOGIN NOVO CONTROL -->
              <v-list-item
                target="_blank"
                :href="conectaControlExternalUrl"
                :disabled="!hasExternalAccessControl"
              >
                <v-list-item-title>
                  Simular login no Control
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>

      <!-- BODY -->
      <div class="overflow-y-auto fill-height" id="client">
        <!-- AVISO DE TOKEN EXPIRADO -->
        <Alert
          v-if="showExpiredTokenAlert && !loading"
          class="mx-5 mt-4 py-4"
          :prominent="false"
          color="error"
        >
          Ops... Parece que o
          <span class="font-weight-bold"
            >token do usuário está expirado ou inválido</span
          >, dessa forma não é possível simular o
          <span class="font-italic">login</span> ou editar este cliente.
        </Alert>

        <v-card
          :outlined="$vuetify.breakpoint.mdAndUp"
          :flat="$vuetify.breakpoint.smAndDown"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'mx-0' : 'mx-5'
          } mt-5 mb-0 pt-2`"
        >
          <v-row class="px-8 py-2">
            <v-col v-if="loading" cols="12" md="6" class="md-pr-7">
              <v-skeleton-loader
                v-for="item in contactItems"
                :key="item.key"
                type="list-item-avatar"
              ></v-skeleton-loader>
            </v-col>
            <!-- CARD DOS DADOS DE CONTATO -->
            <v-col v-else cols="12" md="6" class="md-pr-7">
              <v-card-title
                class="pl-0 pr-0 pt-0 pb-2"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
              >
                Informações da empresa
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text class="pa-0">
                <v-list dense flat v-if="selectedClient">
                  <v-list-item
                    v-for="item in contactItems"
                    :key="item.key"
                    class="px-0 py-0"
                  >
                    <v-list-item-action class="my-0">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on">
                            {{ item.icon }}
                          </v-icon>
                        </template>
                        <span>{{ item.desc }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="item.mask && /^\d+$/.test(item.text)"
                      >
                        <the-mask
                          :style="`color: #000; ${
                            item.key === 'cnpj'
                              ? 'width: 8.5rem'
                              : 'width: 6.8rem'
                          }`"
                          :value="item.text"
                          :mask="item.mask"
                          disabled
                        ></the-mask>
                        <v-btn
                          v-if="item.key === 'verified_phone' && isConecta"
                          :loading="loadingPhoneVerification"
                          icon
                          @click="confirmRemoveVerifiedPhone = true"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                v-text="item.deleteActionIcon"
                              />
                            </template>
                            <span>Remover o telefone do cliente</span>
                          </v-tooltip>
                        </v-btn>
                        <v-btn
                          v-if="
                            item.key === 'verified_phone' &&
                            isConecta &&
                            !loadingPhoneVerification
                          "
                          icon
                          @click="editVerifiedPhoneDialog = true"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                v-text="item.addActionIcon"
                              />
                            </template>
                            <span>Atualizar o telefone do cliente</span>
                          </v-tooltip>
                        </v-btn>
                        <v-btn
                          v-if="item.key === 'verified_phone' && isConecta"
                          icon
                          :loading="loadingIfPhoneIsVerified"
                          @click="checkIfPhoneIsVerified"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                v-text="item.alertIcon"
                                :color="item.alertIconColor"
                              />
                            </template>
                            <span>{{ item.alertIconDesc }}</span>
                          </v-tooltip>
                        </v-btn>
                        <v-btn
                          v-if="item.key === 'cnpj'"
                          :loading="loadingAddressUpdate"
                          icon
                          @click="syncCompanyAddrees(item.text)"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                v-text="item.actionIcon"
                              />
                            </template>
                            <span
                              >Atualizar o endereço do cliente com os dados da
                              Receita federal.</span
                            >
                          </v-tooltip>
                        </v-btn>
                      </v-list-item-title>

                      <v-row v-else-if="item.chip" class="pt-1 pb-2 px-3 mt-0">
                        <div v-if="!item.text.length">Não informado</div>

                        <p
                          v-else
                          class="text-body-2 font-weight-medium py-0 my-0"
                        >
                          <v-chip
                            small
                            class="ma-1 ml-0"
                            v-for="domain in item.text"
                            :key="domain"
                          >
                            {{ domain }}
                          </v-chip>
                        </p>
                      </v-row>

                      <v-list-item-title v-else>
                        {{ item.text }}
                        <v-btn
                          v-if="item.key === 'verified_phone' && isConecta"
                          :loading="loadingPhoneVerification"
                          icon
                          @click="editVerifiedPhoneDialog = true"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                v-text="item.addActionIcon"
                              />
                            </template>
                            <span
                              >Adicionar telefone verificado do cliente</span
                            >
                          </v-tooltip>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-col>

            <!-- CARD DE INFORMAÇÕES DO PLANO -->
            <v-col v-if="loading" cols="12" md="6" class="md-pr-7">
              <v-skeleton-loader
                v-for="item in contactItems.length - 1"
                :key="item.key"
                type="list-item-avatar"
              ></v-skeleton-loader>
            </v-col>
            <v-col v-else cols="12" md="6" class="md-pr-7">
              <v-card-title
                class="pa-0 pb-1"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
              >
                Informações
                <v-spacer></v-spacer>
                <v-btn
                  small
                  outlined
                  text
                  color="accent"
                  class="text-none text-body-2 font-weight-medium mr-0 pl-3 pr-4 mb-2"
                  @click="showEditInfoDialog = true"
                >
                  <v-icon class="mr-2" size="18" v-text="'mdi-pencil'" />Editar
                </v-btn>
              </v-card-title>

              <v-card-text class="py-1 px-0" v-if="selectedClient">
                <v-row class="pt-1 px-3 mt-2 mb-0">
                  <div class="subtitle-2 pt-0 pb-3 my-0 d-flex align-center">
                    Status:
                    <div class="horizontal-scroll overflow-x-hidden">
                      <v-chip
                        v-for="(status, index) in selectedClientStatus"
                        :key="index"
                        small
                        class="mx-2 my-0 caption py-0 font-weight-medium"
                        :color="status.color"
                      >
                        <v-icon v-if="status.icon" v-text="status.icon" small />
                        <span>
                          {{ status.text }}
                        </span>
                      </v-chip>
                    </div>

                    <v-menu offset-y :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          x-small
                          color="accent"
                          text
                          class="text-decoration-underline text-none caption font-weight-medium"
                          :loading="loadingClientStatus"
                        >
                          editar
                        </v-btn>
                      </template>
                      <v-list class="cursor">
                        <v-list-item
                          v-for="(item, index) in clientActions"
                          :key="index"
                          :link="!item.disabled"
                          @click="item.disabled ? undefined : item.action()"
                        >
                          <v-tooltip bottom v-if="item.showTooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                :class="item.disabled ? 'disable-btn' : ''"
                                v-bind="attrs"
                                v-on="on"
                                >{{ item.title }}
                              </v-list-item-title>
                            </template>
                            <span v-if="isSimulation">
                              Não é possivel cancelar cliente enquanto estiver
                              simulando o login.
                            </span>
                            <span v-else-if="!selectedClient.is_banned">
                              Não é possível cancelar clientes que somente
                              possuem planos trial ou nenhum plano ativo.
                            </span>
                            <span v-else>
                              Não é possível cancelar clientes que estão
                              banidos.
                            </span>
                          </v-tooltip>
                          <v-list-item-title
                            v-else
                            :class="item.disabled ? 'disable-btn' : ''"
                            >{{ item.title }}
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </v-row>
                <v-row
                  class="pt-1 pb-2 px-3 mt-0 mb-1"
                  v-if="selectedClient.cancellation_date"
                >
                  <p class="text-body-2 font-weight-medium py-0 my-0">
                    Data de cancelamento:
                    {{ formatDate(selectedClient.cancellation_date) }}
                  </p>
                </v-row>
                <v-row
                  class="pt-1 pb-2 px-3 mt-0 mb-1"
                  v-if="selectedClient.suspended"
                >
                  <p class="text-body-2 font-weight-medium py-0 my-0">
                    Data de suspensão:
                    {{ formatDate(selectedClient.suspended) }}
                  </p>
                </v-row>
                <v-row class="pt-1 pb-2 px-3 mt-0">
                  <p class="text-body-2 font-weight-medium py-0 my-0">
                    informações:
                    <v-chip
                      small
                      v-for="tag in selectedClient.tag"
                      :key="tag + Math.random()"
                      class="mx-1 my-0"
                    >
                      {{ tag }}
                    </v-chip>
                  </p>
                </v-row>
                <v-row class="pt-2 px-3">
                  <p class="text-body-2 font-weight-medium">
                    Data de cadastro na ferramenta:
                    {{ formatDate(selectedClient.created_at) }}
                  </p>
                </v-row>
                <v-row class="pt-0 px-3">
                  <p class="subtitle-2 pt-0 pb-3 my-0 d-flex align-center">
                    Status do contrato:
                    <v-chip
                      small
                      class="mx-2 my-0 caption py-0 font-weight-medium"
                      :color="contractAccepted ? 'success' : 'error'"
                      @click="showContractDialog = contractAccepted"
                    >
                      <v-icon
                        v-text="
                          contractAccepted
                            ? 'mdi-check-circle'
                            : 'mdi-alert-circle-outline'
                        "
                        small
                        class="mr-2"
                      />

                      {{ !contractAccepted ? "Pendente" : "Aceito" }}
                    </v-chip>
                  </p>
                </v-row>

                <v-row class="pt-1 pb-3 px-3" v-if="isConecta">
                  <p class="subtitle-2 py-0 my-0">
                    Faturável:
                    <v-icon
                      :color="isBillable ? 'success' : 'error'"
                      v-text="
                        isBillable
                          ? 'mdi-check-circle-outline'
                          : 'mdi-close-circle-outline'
                      "
                      class="mr-0 my-auto"
                      size="20"
                    />
                    {{ isBillable ? "Sim" : "Não" }}
                  </p>
                </v-row>

                <!--Editar parceiro atual -->
                <v-row
                  class="pt-1 pb-4 px-3"
                  v-if="selectedClient.reseller_company?.name"
                >
                  <p class="subtitle-2 py-0 my-0">
                    Parceiro atual:
                    <v-chip link small class="ma-1" :to="resellerClientRoute">
                      {{ selectedClient.reseller_company?.name }}
                    </v-chip>
                    <v-btn
                      v-if="isConecta"
                      @click="showEditClientResellerDialog = true"
                      text
                      x-small
                      color="accent"
                      class="text-decoration-underline text-none caption font-weight-medium"
                    >
                      Editar
                    </v-btn>
                  </p>
                </v-row>

                <v-card-title
                  class="pa-0 pb-1 mb-5 mt-4"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  Utilização
                  <v-spacer></v-spacer>
                </v-card-title>

                <v-card-subtitle class="pl-0 subtitle-2 mt-3">
                  Conecta Suite & Sign
                </v-card-subtitle>

                <v-card-text class="pt-1 pl-0 pb-0">
                  <v-col class="ma-0 pa-0 d-inline-flex">
                    <p class="subtitle-2 mr-1">Último login :</p>

                    <span v-if="!!selectedClient.last_seen">
                      {{
                        formatDateAndHour(
                          selectedClient.last_seen,
                          (without_time_zone = true)
                        )
                      }}
                    </span>
                    <span
                      v-else
                      class="grey--text text--darken-3 font-italic font-weight-regular"
                    >
                      não encontrado
                    </span>
                    <div class="ml-2 mt-0 pt-0">
                      <TooltipIcon
                        icon="mdi-help-circle-outline"
                        size="17"
                        color="secondary lighten-3"
                        label="Última vez que algum usuário realizou o login no Suite"
                      />
                    </div>
                  </v-col>
                  <v-col class="ma-0 pa-0 d-inline-flex">
                    <p class="subtitle-2 mr-1">Última ação:</p>
                    <v-progress-circular
                      v-if="loadingLastSeen"
                      indeterminate
                      color="blue-grey"
                      size="18"
                      class="ml-4"
                    ></v-progress-circular>
                    <span v-else-if="lastSeen.suite.length">
                      {{
                        formatDateAndHour(
                          lastSeen.suite,
                          (without_time_zone = false)
                        )
                      }}
                    </span>
                    <span
                      v-else
                      class="grey--text text--darken-3 font-italic font-weight-regular"
                    >
                      não encontrado
                    </span>
                    <div class="ml-2 mt-0 pt-0">
                      <TooltipIcon
                        icon="mdi-help-circle-outline"
                        size="17"
                        color="secondary lighten-3"
                        label="Última vez que algum usuário realizou uma ação que registrou log no Suite ou Sign"
                      />
                    </div>
                  </v-col>
                  <v-col class="ma-0 pa-0 d-inline-flex">
                    <p class="subtitle-2 mr-1">Primeira aplicação em massa:</p>
                    <span v-if="!!selectedClient.first_bulk_signature_apply">
                      {{
                        formatDateAndHour(
                          selectedClient.first_bulk_signature_apply,
                          (without_time_zone = true)
                        )
                      }}
                    </span>
                    <span
                      v-else
                      class="grey--text text--darken-3 font-italic font-weight-regular"
                    >
                      não encontrado
                    </span>
                    <div class="ml-2 mt-0 pt-0">
                      <TooltipIcon
                        icon="mdi-help-circle-outline"
                        size="17"
                        color="secondary lighten-3"
                        label="Primeira vez que uma assinatura de email foi aplicada em massa via Sign"
                      />
                    </div>
                  </v-col>
                </v-card-text>

                <v-card-subtitle class="pl-0 subtitle-2 pt-0"
                  >Conecta Control
                </v-card-subtitle>
                <v-card-text class="pl-0">
                  <v-col class="ma-0 pa-0 d-inline-flex">
                    <p class="subtitle-2 mr-1">Último login:</p>
                    <span v-if="!!selectedClient.control_last_seen">
                      {{
                        formatDateAndHour(
                          selectedClient.control_last_seen,
                          (without_time_zone = true)
                        )
                      }}
                    </span>
                    <span
                      v-else
                      class="grey--text text--darken-3 font-italic font-weight-regular"
                    >
                      não encontrado
                    </span>
                    <div class="ml-2 mt-0 pt-0">
                      <TooltipIcon
                        icon="mdi-help-circle-outline"
                        size="17"
                        color="secondary lighten-3"
                        label="Última vez que algum usuário realizou o login no
                          painel do Control"
                      />
                    </div>
                  </v-col>
                  <v-col class="ma-0 pa-0 d-inline-flex">
                    <p class="subtitle-2 mr-1">Última ação:</p>
                    <v-progress-circular
                      v-if="loadingLastSeen"
                      indeterminate
                      color="blue-grey"
                      size="18"
                      class="ml-4"
                    ></v-progress-circular>
                    <span v-else-if="lastSeen.control.length">
                      <!--
                        quando utilizar o utc
                        https://conectanuvem.atlassian.net/wiki/spaces/CS/pages/1890975745/sobre+datas -->
                      {{
                        formatDateAndHour(
                          lastSeen.control,
                          (without_time_zone = false)
                        )
                      }}
                    </span>
                    <span
                      v-else
                      class="grey--text text--darken-3 font-italic font-weight-regular"
                    >
                      não encontrado
                    </span>
                    <div class="ml-2 mt-0 pt-0">
                      <TooltipIcon
                        icon="mdi-help-circle-outline"
                        size="17"
                        color="secondary lighten-3"
                        label="Última vez que um usuário realizou uma ação que registrou log no painel do Control"
                      />
                    </div>
                  </v-col>
                  <v-col class="ma-0 pa-0 d-inline-flex">
                    <p class="subtitle-2 mr-1">Primeira ativação:</p>
                    <span v-if="!!selectedClient.sso_first_activation">
                      {{
                        formatDateAndHour(
                          selectedClient.sso_first_activation,
                          (without_time_zone = true)
                        )
                      }}
                    </span>
                    <span
                      v-else
                      class="grey--text text--darken-3 font-italic font-weight-regular"
                    >
                      não encontrado
                    </span>
                    <div class="ml-2 mt-0 pt-0">
                      <TooltipIcon
                        icon="mdi-help-circle-outline"
                        size="17"
                        color="secondary lighten-3"
                        label="Primeira vez que um usuário ativou o Control via painel do Control"
                      />
                    </div>
                  </v-col>
                </v-card-text>

                <!-- ÁREA DOS CLIENTES QUE SÃO PARCEIROS -->
                <v-card-title
                  class="pa-0 d-flex align-center pb-1 mt-0"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
                  v-if="isConecta"
                >
                  Conecta Partner
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1"
                        size="20"
                        v-bind="attrs"
                        v-on="on"
                        :color="!clientIsReseller ? 'error' : 'success'"
                        v-text="
                          !clientIsReseller
                            ? 'mdi-close-circle-outline'
                            : 'mdi-check-circle-outline'
                        "
                      />
                    </template>
                    <span
                      >Para atribuir ou editar a função de Conecta Partner a
                      este parceiro, clique no botão editar à direita.</span
                    >
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn
                    small
                    outlined
                    text
                    color="accent"
                    class="text-none text-body-2 font-weight-medium mr-0 pl-3 pr-4 mb-2"
                    @click="showEditConectaPartner = true"
                  >
                    <v-icon class="mr-2" size="18" v-text="'mdi-pencil'" />
                    Editar
                  </v-btn>
                </v-card-title>

                <v-row
                  class="py-1 px-4 mt-2"
                  v-if="!selectedClient.is_reseller"
                >
                  <p
                    class="subtitle-2 grey--text text--darken-1 font-weight-regular"
                  >
                    Este cliente não é parceiro Conecta
                  </p>
                </v-row>
                <div v-else class="mt-5">
                  <v-row class="pt-1 px-3">
                    <p class="text-body-2 font-weight-medium">
                      Tipo de parceria:
                      {{
                        translateResellerTypes[
                          selectedClient.reseller_config.type
                        ]
                      }}
                    </p>
                  </v-row>
                  <v-row class="pt-0 px-3">
                    <p class="text-body-2 font-weight-medium">
                      Conecta Support:
                      {{
                        selectedClient.reseller_config.is_support_chat_available
                          ? "Sim"
                          : "Não"
                      }}
                    </p>
                  </v-row>
                  <v-row class="pt-0 px-3">
                    <p class="text-body-2 font-weight-medium">
                      Desconto ao cliente final:
                      {{ selectedClient.reseller_config.discount * 100 }}%
                    </p>
                  </v-row>
                  <v-row class="pt-0 px-3">
                    <p class="text-body-2 font-weight-medium">
                      Comissão de parceiro:
                      {{ selectedClient.reseller_config.commission * 100 }}%
                    </p>
                  </v-row>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <Alert
          v-if="!checkResellerPermission() && !loading"
          class="mx-5 mt-5"
          max_width="1540"
          :prominent="false"
          color="info"
        >
          <span class="font-weight-bold">ATENÇÃO:</span> Esse cliente é
          gerenciado por outra empresa!
        </Alert>

        <!-- CARD DAS ASSINATURAS -->
        <v-card
          id="signatures"
          :class="`${
            $vuetify.breakpoint.smAndDown ? 'mt-4' : 'mx-5 my-5'
          } pb-4 elevation-0`"
          :outlined="$vuetify.breakpoint.mdAndUp"
          :flat="$vuetify.breakpoint.smAndDown"
        >
          <v-progress-linear
            v-if="showLoadingModules == true"
            indeterminate
            color="primary"
          ></v-progress-linear>

          <v-card-title
            :style="`${
              $vuetify.breakpoint.xs
                ? ''
                : 'border-bottom: 1px solid rgba(0, 0, 0, 0.12)'
            }`"
            class="px-5 py-4 ma-0"
          >
            {{ isConecta ? "Assinaturas e serviços" : "Assinaturas" }}
            <v-spacer></v-spacer>
            <v-btn
              v-if="isConecta"
              outlined
              :block="$vuetify.breakpoint.xs"
              :large="$vuetify.breakpoint.xs"
              text
              color="accent"
              :class="`text-none text-body-2 font-weight-medium mr-2 px-4 ${
                $vuetify.breakpoint.xs ? 'mt-4' : ''
              }`"
              @click="createNewService"
            >
              <v-icon v-text="'mdi-account-wrench'" class="mr-2" />
              Novo serviço
            </v-btn>

            <v-btn
              v-if="isConecta && $vuetify.breakpoint.xs"
              outlined
              block
              large
              text
              color="accent"
              :class="`text-none text-body-2 font-weight-medium mr-2 px-4 ${
                $vuetify.breakpoint.xs ? 'mt-2' : ''
              }`"
              @click="createNewService"
            >
              <v-icon v-text="'mdi-layers-plus'" class="mr-2" />
              Novo plano
            </v-btn>

            <v-btn
              outlined
              :block="$vuetify.breakpoint.xs"
              :large="$vuetify.breakpoint.xs"
              text
              color="accent"
              :class="`text-none text-body-2 font-weight-medium mr-1 px-4 ${
                $vuetify.breakpoint.xs ? 'mt-2' : ''
              }`"
              @click="showDeactives = !showDeactives"
            >
              <v-icon
                v-text="showDeactives ? 'mdi-table-eye-off' : 'mdi-table-eye'"
                class="mr-2"
              />
              {{
                showDeactives
                  ? "Ocultar planos desativados"
                  : "Mostrar planos desativados"
              }}
            </v-btn>
          </v-card-title>

          <Alert
            v-if="resellerClientDoNotHaveCnpj && !loading"
            color="error"
            class="mx-4 mt-4"
            :text="false"
            dark
          >
            Esta empresa <b>não possui CNPJ cadastrado</b>. Conclua o cadastro
            antes de adquirir novos planos ou serviços para ela.
          </Alert>

          <v-row
            :class="`text-center justify-start align-center flex-wrap py-4 px-5`"
            align-content="center"
            justify="start"
            justify-xs="center"
          >
            <v-col
              cols="12"
              v-if="$vuetify.breakpoint.smAndUp"
              sm="4"
              md="3"
              xl="2"
            >
              <PurchasedPlan
                is-new
                :class="isMobile ? '' : 'mr-4'"
                @click.native="confirmEditDialog()"
              />
            </v-col>

            <v-col v-if="loading" cols="12" xs="12" sm="8">
              <v-progress-circular
                indeterminate
                color="secondary lighten-2"
                size="40"
                width="4"
              />
              <div class="mt-2">Carregando...</div>
            </v-col>

            <v-col
              cols="12"
              xs="12"
              sm="4"
              md="3"
              xl="2"
              v-for="(plan, i) in allPlans"
              :key="i"
              :data-testid="`company-plan`.replace(/ /g, '')"
            >
              <PurchasedPlan
                :plan="plan"
                :styles="isSelectedPlan(plan) ? 'selected-card' : ''"
                :licenses-number="selectedClient.users_number"
                :class="isMobile ? '' : 'mr-4'"
                @click.native="confirmEditDialog(plan)"
              />
            </v-col>
          </v-row>

          <MoreInfoCardReseller
            id="companyPlanInfo"
            v-if="editedPlan"
            v-show="showModuleInfo && currentPlanSelectToEdition"
            :client-name="clientName"
            :logo="editedCompanyPlanLogo"
            :plan="editedPlan || { plan: {} }"
            @editTrialDate="editTrialDate"
          />
        </v-card>
        <!--CARD EDIÇÃO TRANSACTION-->
        <EditFinancialTransaction
          v-if="isConecta || isReseller"
          :activePlans="activePlans"
          :clientKey="clientKey"
          :clientName="clientName"
          :licenses="selectedClient.users_number"
        />

        <FeatureUsageCount :clientKey="clientKey" />

        <CardHistoryLog
          v-if="selectedClient && isConecta"
          :clientKey="clientKey"
          :key="clientKey"
        />

        <v-divider></v-divider>
        <v-card-title>
          <span>Relatórios do Suite</span>
        </v-card-title>
        <ReportsGeneral />
        <ReportsSignatures />
        <AccountsReports />
      </div>
    </v-card>

    <AddClientToReseller
      v-if="selectedClient && isConecta"
      :show="showEditClientResellerDialog"
      :client_key="clientKey"
      :client_reseller_key="clientResellerToken"
      @closeAddClientReseller="showEditClientResellerDialog = false"
      @showSuccessResellerChangeMessage="showSuccessResellerChange"
      @showErrorResellerChangeMessage="showFailureResellerChange"
      @showFailureRemoveReseller="showFailureRemoveReseller"
      @showSuccessRemoveReseller="showSuccessRemoveReseller"
    />

    <v-snackbar v-model="showSuccess" color="success" multi-line>
      {{ successMessages[successMessage] }}
      <span v-if="oldResellerName" class="font-weight-medium">{{
        oldResellerName
      }}</span>
      <template
        v-slot:action="{ attrs }"
        v-if="successMessage !== 'newStatusReseller'"
      >
        <v-btn dark text v-bind="attrs" @click="showSuccess = false"
          >Fechar</v-btn
        >
      </template>
    </v-snackbar>

    <v-snackbar color="error" v-model="showError" multi-line>
      {{ errorMessages[errorMessage] }}
      <span v-if="oldResellerName" class="font-weight-medium">{{
        oldResellerName
      }}</span>
      <template
        v-slot:action="{ attrs }"
        v-if="successMessage !== 'newStatusReseller'"
      >
        <v-btn text v-bind="attrs" @click="showError = false">Fechar</v-btn>
      </template>
    </v-snackbar>

    <!-- V-DIALOG SHOW CONTRACT -->
    <ContractInfo
      v-if="selectedClient.contract"
      :show="showContractDialog"
      :client="selectedClient"
      :clientName="clientName"
      @close="showContractDialog = false"
    />

    <ConfirmPlanSubscription
      :show="showDialogConfirmStatus"
      @close="closeEditingCompanyPlanStatus"
      @save="saveEditingCompanyPlanStatus"
      :isTrial="newStatus === 'TRIAL'"
    />

    <!-- V-DIALOG EDIT STATUS -->
    <HeaderDialog
      :show="showEditInfoDialog"
      color="primary"
      title="Editar informações"
      :loading="loadingEditionStatus"
      :disabledAction="editInfoDisabled"
      action-text="Salvar"
      close-text="Cancelar"
      width="700"
      @close="resetEditedInfo()"
      @action="editInfo()"
    >
      <template v-slot:body>
        <v-card-subtitle class="pa-0 ma-0 font-weight-medium text-body-1 mt-2">
          informações da empresa
          <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-text="'mdi-help-circle-outline'"
                v-bind="attrs"
                v-on="on"
                class="ml-1"
                size="20"
                color="blue darken-2"
              />
            </template>
            <span>Defina informações para identificar melhor este cliente</span>
          </v-tooltip>
        </v-card-subtitle>

        <v-chip-group
          v-model="editedTag"
          column
          multiple
          class="px-0 align-center mt-2"
        >
          <v-chip
            :value="tag"
            v-for="(tag, i) in existentTags"
            :key="i"
            filter
            outlined
          >
            {{ tag }}
          </v-chip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                pill
                height="35"
                @click.stop="showNewTag = !showNewTag"
                class="my-1 text-none text-subtitle-2 rounded-xl accent--text"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  v-text="showNewTag ? 'mdi-pencil-off' : 'mdi-pencil'"
                  small
                  left
                />
                Gerenciar informações
              </v-btn>
            </template>
            <span>Edtar/adicionar mais Informações</span>
          </v-tooltip>
        </v-chip-group>

        <v-combobox
          v-if="showNewTag"
          v-model="editedTag"
          :items="tagList"
          :search-input.sync="searchTags"
          label="Inserir informações"
          placeholder="Insira as informações usando vírgulas ou ponto e vírgulas"
          color="primary"
          item-color="grey"
          class="mt-2"
          multiple
          small-chips
          deletable-chips
          hide-selected
          :delimiters="[',', ';']"
          filled
          append-icon=""
          hide-details
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  Nenhum resultado para "<strong>{{ searchTags }}</strong
                  >". Pressione <kbd>enter</kbd> para criar uma nova tag.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>

        <v-card-subtitle class="pa-0 ma-0 font-weight-medium mt-8 text-body-1">
          Faturamento
          <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-text="'mdi-help-circle-outline'"
                v-bind="attrs"
                v-on="on"
                class="ml-1"
                size="20"
                color="blue darken-2"
              />
            </template>
            <span>Defina se esse cliente deve ser faturado</span>
          </v-tooltip>
        </v-card-subtitle>

        <v-switch
          v-model="isBillable"
          class="mx-1 font-weigh-medium"
          inset
          :label="`${
            isBillable
              ? 'Empresa definida como faturável'
              : 'Empresa definida como não faturável'
          }`"
        ></v-switch>
      </template>
    </HeaderDialog>

    <!-- DIALOG PARA EDIÇÃO DE TELEFONE VERIFICADO -->
    <HeaderDialog
      title="Editar telefone do cliente"
      :loading="loadingPhoneVerification"
      :show="editVerifiedPhoneDialog"
      @close="editVerifiedPhoneDialog = false"
      @action="saveVerifiedPhone()"
      width="400"
    >
      <template v-slot:body>
        <v-text-field
          v-model="verifiedPhone"
          label="Telefone"
          hint="Digite somente os números"
        ></v-text-field>
      </template>
    </HeaderDialog>

    <!-- DIALOG DE CONFIRMAÇÃO PARA REMOVER TELEFONE VERIFICADO -->
    <HeaderDialog
      title="Remover telefone do cliente"
      color="red"
      :loading="loadingPhoneVerification"
      :show="confirmRemoveVerifiedPhone"
      @close="confirmRemoveVerifiedPhone = false"
      @action="requestPhoneVerification()"
      width="400"
    >
      <template v-slot:body>
        <p>Deseja remover o telefone verificado do cliente?</p>
      </template>
    </HeaderDialog>

    <!-- DIALOG EDIT CONFIRMATION -->
    <SimpleDialog
      v-if="selectedClient"
      id="confirm-edit"
      title="Tem certeza que deseja editar esse cliente?"
      :show="showConfirmationDialog"
      @close="showConfirmationDialog = false"
      @action="goToPlanDetails(selectedCompanyPlan)"
    >
      <template v-slot:body>
        <div v-if="selectedClient.reseller_company">
          Esse cliente é gerenciado por
          <strong>{{ selectedClient.reseller_company?.name }}.</strong>
          Tem certeza que deseja editá-lo?
        </div>
      </template>
    </SimpleDialog>

    <!-- DIALOG CONFIRMATION TO CANCEL CLIENT  -->
    <ConfirmationDialog
      v-if="selectedClient"
      :show="showConfirmCancelClient"
      :target="clientName"
      action-text="Cancelar o cliente"
      :loading="loadingClientStatus"
      width="700"
      @close="showConfirmCancelClient = false"
      @ok="cancelClient()"
      hideReversibleText
      :alert="{
        show: true,
        message:
          'ATENÇÃO: o cancelamento NÃO deve ser utilizado com o objetivo de desabilitar um plano, pois os planos ativos são desativados automaticamente na contratação de um novo plano.',
        type: 'warning',
      }"
      color="warning"
      dense
      customBodyClass="px-0"
      message="<p>Ao realizar o cancelamento, todos os planos ativos serão
        <strong>desativados</strong>.</p>
        <div>
          Nenhum bloqueio será aplicado! Se o cliente ou parceiro contratarem um
          novo plano, o cliente voltará a estar ativo automaticamente.
        </div>"
    >
      <v-textarea
        v-model="cancelationReason"
        name="input-3-1"
        label="Motivo do cancelamento"
        auto-grow
        outlined
      ></v-textarea>
    </ConfirmationDialog>

    <!-- DIALOG CONFIRMATION TO SUSPEND CLIENT  -->
    <ConfirmationDialog
      v-if="selectedClient"
      :show="showConfirmSuspendClient"
      :target="clientName"
      :actionText="
        selectedClient.suspended ? 'Remover suspensão' : 'Suspender o cliente'
      "
      :btnActionText="
        selectedClient.suspended ? 'Remover suspensão' : 'Suspender cliente'
      "
      :loading="loadingClientStatus"
      width="700"
      @close="showConfirmSuspendClient = false"
      @ok="selectedClient.suspended ? unsuspendClient() : suspendClient()"
      hideReversibleText
      customBodyClass="px-0"
      :message="
        selectedClient.suspended
          ? `
      <p class='mt-4'>
        Quando a suspensão é removida, o acesso do cliente ao sistema será restaurado, e os planos anteriormente suspensos serão reativados, resultando na geração normal de faturas.
      </p>
      `
          : `
      <p class='mt-4'>Ao realizar a <strong>suspensão</strong>, os planos ativos serão suspensos e deixam de gerar faturas.</p>
      <div>
        O acesso do cliente às ferramentas do suite, sign e control será bloqueado, sendo redirecionado para uma página com instruções de pagamento.
      </div>`
      "
    >
      <v-textarea
        v-model="suspensionReason"
        name="input-3-1"
        label="Motivo da suspensão"
        auto-grow
        outlined
      ></v-textarea>
    </ConfirmationDialog>

    <!-- DIALOG CONFIRMATION TO CANCEL AND SUSPEND CLIENT  -->
    <ConfirmationDialog
      v-if="selectedClient"
      :show="showConfirmCancelAndSuspendClient"
      :target="clientName"
      :actionText="'Cancelar o cliente'"
      :btnActionText="'Cancelar cliente'"
      :loading="loadingClientStatus"
      width="700"
      @close="showConfirmCancelAndSuspendClient = false"
      @ok="cancelAndSuspendClient()"
      hideReversibleText
      customBodyClass="px-0"
      :message="`
      <p class='mt-4'>Ao realizar o <strong>cancelamento</strong>, os planos ativos serão cancelados e o cliente será suspenso.</p>
      <div>
        O acesso do cliente às ferramentas do suite, sign e control será bloqueado, sendo redirecionado para uma página com instruções de pagamento.
      </div>`"
    >
      <v-textarea
        v-model="cancelAndSuspendReason"
        name="input-3-1"
        :label="'Motivo do cancelamento'"
        auto-grow
        outlined
      ></v-textarea>
    </ConfirmationDialog>

    <AddEditReseller
      v-if="isConecta && conectaPartnerInfo"
      :show="showEditConectaPartner"
      @close="showEditConectaPartner = false"
      :client-key="clientKey"
      :reseller-status="editIsReseller"
      :reseller-token="clientCSToken"
      :resellerInfo="conectaPartnerInfo"
      @hideShowScrollBar="hideShowScrollBar"
      @startLoadClient="startLoadClient"
      @showSuccessUpdateResellerStatus="showSuccessUpdateResellerStatus"
      @showFailureUpdateResellerStatus="showFailureUpdateResellerStatus"
    />
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import MenuClientSearch from "@/components/reseller/buttons/MenuClientSearch";
import CardHistoryLog from "@/components/reseller/cards/CardHistoryLog.vue";
import EditFinancialTransaction from "@/components/reseller/cards/EditFinancialTransaction";
import FeatureUsageCount from "@/components/reseller/cards/FeatureUsageCount";
import MoreInfoCardReseller from "@/components/reseller/cards/MoreInfoCardReseller";
import PurchasedPlan from "@/components/reseller/cards/PurchasedPlan";
import AddClientToReseller from "@/components/reseller/dialogs/AddClientToReseller";
import AddEditReseller from "@/components/reseller/dialogs/AddEditReseller";
import ConfirmPlanSubscription from "@/components/reseller/dialogs/ConfirmPlanSubscription";
import ContractInfo from "@/components/reseller/dialogs/ContractInfo";
import { updateControlDomainStatus } from "@/helpers/services/conectaControl";
import { changeClientModuleStatus } from "@/helpers/services/resellerClient";
import {
  formatDate,
  formatDateAndHour,
  formatDateForDatabase,
  getTheEndOfTrial,
} from "@/helpers/services/utils";
import { STATUS, SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import { modules } from "@/helpers/variables/modules";
import {
  productNames,
  status,
  translateResellerTypes,
} from "@/helpers/variables/translateString";
import AccountsReports from "@/views/admin/AccountsReports.vue";
import ReportsGeneral from "@/views/admin/ReportsGeneral.vue";
import ReportsSignatures from "@/views/admin/ReportsSignatures.vue";
import { TheMask } from "vue-the-mask";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "Customer",

  components: {
    TheMask,
    MoreInfoCardReseller,
    MenuClientSearch,
    ContractInfo,
    ConfirmPlanSubscription,
    AddClientToReseller,
    AddEditReseller,
    EditFinancialTransaction,
    PurchasedPlan,
    CardHistoryLog,
    ConfirmationDialog,
    ReportsGeneral,
    ReportsSignatures,
    AccountsReports,
    FeatureUsageCount,
  },

  data() {
    return {
      loadingPhoneVerification: false,
      editVerifiedPhoneDialog: false,
      confirmRemoveVerifiedPhone: false,
      verifiedPhone: "",
      showNewTag: false,
      STATUS,
      SUBSCRIPTIONS,
      hasTrialPlan: false,
      hasActivePlan: false,
      offsetTop: 0,
      conectaPartner: null,
      changeIsReseller: false,
      showEditConectaPartner: false,
      oldResellerName: null,
      successMessages: {
        newStatusReseller: `As informações deste Conecta Partner foram atualizadas com sucesso.`,
        newReseller: `O cliente foi atribuído ao parceiro `,
        resellerRemove: `A atribuição deste cliente foi removida do parceiro `,
        success: "Atualização salva com sucesso",
      },
      errorMessages: {
        newStatusReseller: `As informações deste Conecta Partner não puderam ser atualizadas.`,
        newReseller: `Não foi possível alterar o revendedor deste cliente`,
        resellerRemove: `A atribuição deste cliente não pode ser removida do parceiro `,
        failure: "Ocorreu um erro, tente novamente mais tarde",
        failureProduct:
          "Ocorreu um erro, o produto que deseja ativar já está ativo em outro plano.",
        editFailure: "Esse cliente é gerenciado por outra empresa!",
        cnpjRequestsExceeded:
          "Você excedeu o número de consultas, aguarde 24h para tentar novamente",
        ERROR: "CNPJ não encontrado",
        LIMIT_EXCEEDED: "Tente novamente em um minuto",
        BAD_REQUEST: "cnpj não informado",
      },
      errorMessage: null,
      successMessage: null,
      showError: false,
      showSuccess: false,
      showEditClientResellerDialog: false,
      translateResellerTypes,
      editedPlan: null,
      selectedCompanyPlan: {},
      showPaymentPlan: false,
      show: true,
      loadingModuleStatus: false,
      searchTags: null,
      modules,
      showLoadingModules: true,
      loadingEditionStatus: false,
      showEditInfoDialog: false,
      showContractDialog: false,
      showConfirmationDialog: false,
      loadingClientStatus: false,

      showConfirmCancelClient: false,
      showConfirmSuspendClient: false,
      showConfirmCancelAndSuspendClient: false,

      showLoadingConfirm: false,

      price: null,
      editedModule: null,

      editedTag: [],

      mainDomain: null,
      clientToken: null,
      status,
      controlStatus: "",
      loadingSimulation: false,
      tagList: [
        "Beta Tester",
        "Cliente Pagante",
        "Reseller",
        "Trial",
        "BetaTesterSSO",
        "dashTester",
      ],

      breadcrumbs: [
        {
          text: "Clientes",
          disabled: false,
          to: "/reseller/clients",
        },
        {
          text: "",
          disabled: false,
          to: "",
        },
      ],
      cards: [],
      editedCompanyPlan: "",
      editedCompanyPlanLogo: "",
      showDialogConfirmStatus: false,
      newStatus: null,
      loading: false,
      pageHeight: 0,
      generalClientInfo: [
        { key: "name", text: "", icon: "mdi-store", desc: "Nome do cliente" },
        {
          key: "main_domain",
          text: "",
          icon: "mdi-domain",
          desc: "Domínio principal",
        },
        {
          key: "users",
          text: "",
          icon: "mdi-account-multiple",
          desc: "Número de licenças",
        },
        {
          key: "responsible_name",
          text: "",
          icon: "mdi-account-circle",
          desc: "Nome do responsável",
        },
        {
          key: "responsible_email",
          text: "",
          icon: "mdi-email-outline",
          desc: "E-mail do responsável",
        },
        {
          key: "financial_email",
          text: "",
          icon: "mdi-email-outline",
          desc: "E-mail do financeiro",
        },
        {
          key: "phone",
          text: "",
          icon: "mdi-phone",
          desc: "Telefone",
          mask: ("(##) ####-####", "(##) #####-####"),
        },
        {
          key: "verified_phone",
          text: "",
          icon: "mdi-phone-check",
          desc: "Telefone verificado",
          mask: ("(##) ####-####", "(##) #####-####"),
          addActionIcon: "mdi-pencil-plus-outline",
          deleteActionIcon: "mdi-delete-outline",
          alertIcon: "mdi-help-circle-outline",
          alertIconColor: "",
          alertIconDesc:
            "Clique para verificar se o telefone foi validado por SMS",
        },
        {
          key: "cnpj",
          text: "",
          icon: "mdi-card-account-details",
          mask: "##.###.###/####-##",
          actionIcon: "mdi-cached",
          desc: "CNPJ",
        },
        {
          key: "address_postal_code",
          text: "",
          icon: "mdi-crosshairs-gps",
          desc: "CEP",
          mask: "#####-###",
        },
        {
          key: "district",
          text: "",
          icon: "mdi-map-marker",
          desc: "Bairro",
        },
        {
          key: "address",
          /* text: this.contactAddress, */
          icon: "mdi-map-marker-multiple",
          desc: "Endereço",
        },
        {
          key: "id_google",
          icon: "mdi-google",
          desc: "ID Google",
        },
        {
          key: "superlogica_customer_id",
          icon: "mdi-finance",
          desc: "ID Superlógica",
        },
        {
          key: "domains_list",
          chip: true,
          icon: "mdi-domain-plus",
          desc: "Domínios",
        },
      ],
      showDisabledPlans: false,
      isBillable: false,
      loadingAddressUpdate: false,
      loadingLastSeen: false,
      lastSeen: {
        suite: "",
        control: "",
      },

      cancelationReason: "",
      suspensionReason: "",
      cancelAndSuspendReason: "",

      loadingIfPhoneIsVerified: false,
    };
  },

  computed: {
    ...mapGetters([
      "company",
      "clients",
      "token",
      "isReseller",
      "isConecta",
      "isSimulation",
      "hasExternalAccessControl",
      "getSelectedResellerClientError",
      "resellerClientDoNotHaveCnpj",
      "pageGap",
    ]),

    clientActions() {
      return [
        {
          title: "Cancelar Cliente",
          action: this.openCancelClientDialog,
          disabled: this.statusDisableCancelButton,
          show: true,
          showTooltip: this.statusDisableCancelButton,
        },
        {
          title: "Suspender por inadimplência",
          action: this.openSuspendClientDialog,
          show: !this.selectedClient.suspended,
        },
        {
          title: "Remover suspensão",
          action: this.openSuspendClientDialog,
          show: !!this.selectedClient.suspended,
        },
        {
          title: "Cancelar por inadimplência",
          action: this.openCancelAndSuspendClientDialog,
          disabled: this.statusDisableCancelAndSuspendButton,
          show: true,
          showTooltip: this.statusDisableCancelAndSuspendButton,
        },
      ].filter((action) => action.show);
    },

    selectedClient() {
      return this.$store.getters.selectedClient || this.returnCompanyValues;
    },

    selectedClientStatus() {
      const { cancellation_date, is_banned, suspended } = this.selectedClient;

      const statusOptions = [
        {
          condition: !cancellation_date && !is_banned && !suspended,
          color: "success",
          icon: "mdi-check-circle",
          text: "Ativo",
        },
        { condition: cancellation_date, color: "error", text: "Cancelado" },
        { condition: suspended, color: "warning", text: "Suspenso" },
        { condition: is_banned, color: "error", text: "Banido" },
      ];

      return statusOptions
        .filter((option) => option.condition)
        .map((option) => ({
          color: option.color,
          icon: option.icon,
          text: option.text,
        }));
    },

    returnCompanyValues() {
      return {
        tag: [],
        reseller_company: {},
        users_number: 0,
      };
    },

    clientIsCancelledAndSuspended() {
      return (
        this.selectedClient.cancellation_date && this.selectedClient.suspended
      );
    },

    statusDisableCancelButton() {
      return (
        this.loadingSimulation ||
        this.isSimulation ||
        (this.selectedClient.cancellation_date ? true : false) ||
        this.clientIsCancelledAndSuspended ||
        !this.hasActivePlan ||
        this.selectedClient.is_banned
      );
    },

    statusDisableCancelAndSuspendButton() {
      return (
        this.loadingSimulation ||
        this.isSimulation ||
        this.clientIsCancelledAndSuspended ||
        this.selectedClient.is_banned
      );
    },

    existentTags() {
      let tags = [...this.tagList];
      if (this.selectedClient) {
        tags = [...tags, ...this.selectedClient.tag];
      }
      return Array.from(new Set(tags));
    },

    planKey() {
      return this.currentPlanSelectToEdition
        ? this.currentPlanSelectToEdition.plan.key
        : "";
    },

    currentPlanSelectToEdition: {
      get() {
        return this.editedPlan;
      },
      set(new_value) {
        this.editedPlan = new_value;
      },
    },

    editedPlanStatus() {
      return this.currentPlanSelectToEdition
        ? this.currentPlanSelectToEdition.status
        : "";
    },

    clientCSToken() {
      return this.selectedClient && this.selectedClient.cs_token;
    },

    showDeactives: {
      get() {
        return this.showDisabledPlans;
      },
      set() {
        this.showDisabledPlans = !this.showDisabledPlans;
        if (!this.showDisabledPlans) {
          this.editedCompanyPlan = "";
          this.editedCompanyPlanLogo = "";
        }
      },
    },

    conectaPartnerInfo() {
      return this.conectaPartner;
    },

    editIsReseller: {
      get() {
        return this.changeIsReseller;
      },
      set() {
        this.changeIsReseller = !this.changeIsReseller;
      },
    },

    clientIsReseller() {
      return this.selectedClient.is_reseller;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    clientKey() {
      return this.$route.query.key;
    },

    clientResellerToken() {
      return this.selectedClient.reseller_token;
    },

    conectaControlExternalUrl() {
      return `${process.env.VUE_APP_CONECTA_NEW_CONTROL_BASE_URL}/auth/login-as?suiteKey=${this.clientKey}`;
    },

    allPlans() {
      return this.showDisabledPlans ? this.cards : this.activePlans;
    },

    activePlans() {
      return this.cards.reduce((acc, plan) => {
        if (plan.subscription_type === SUBSCRIPTIONS.ONCE && this.isConecta) {
          acc.push(plan);
        } else if (plan.status !== STATUS.DISABLED) {
          acc.push(plan);
        }
        return acc;
      }, []);
    },

    clientName() {
      return this.selectedClient?.name || "";
    },

    hasControlModuleAtSuite() {
      return this.cards.find((card) => card.name === "conecta_control");
    },

    showModuleInfo() {
      return !!this.editedCompanyPlan && this.editedCompanyPlanLogo;
    },

    allClients() {
      return this.clients.map((item) => {
        return {
          name: item.name,
          main_domain: item.main_domain,
          key: item.key,
        };
      });
    },

    resellerClientRoute() {
      const reseller = this.clients.find(
        (client) =>
          client.company.main_domain ===
          this.selectedClient.reseller_company.domain
      );

      if (reseller) {
        return {
          path: "/reseller/client",
          query: {
            domain: reseller.company.main_domain,
            key: reseller.company.key,
          },
        };
      }
      return null;
    },

    showExpiredTokenAlert() {
      return !this.loadingSimulation && !this.clientToken;
    },

    contactItems() {
      return this.generalClientInfo.map((item) => {
        if (item.key === "address") {
          item.text = this.contactAddress;
        } else if (item.key === "users") {
          item.text = this.selectedClient.users_number + " licenças ativas";
        } else if (item.key == "domains_list") {
          item.text = this.selectedClient?.[item.key] || [];
        } else {
          item.text = this.selectedClient?.[item.key] || "Não informado";
          if (item.key === "phone") {
            item.text = this.formatPhoneNumber(item.text);
          } else if (item.key === "verified_phone") {
            item.text = this.formatPhoneNumber(item.text);
          }
        }
        return item;
      });
    },

    contactAddress() {
      const address_keys = [
        "address_info",
        "address_number",
        "address_complement",
        "address_city",
        "address_state",
        "address_country",
      ];
      if (this.selectedClient) {
        return address_keys
          .map((key) => this.selectedClient?.[key])
          .filter((value) => value)
          .join(", ");
      }
      return [];
    },

    contractAccepted() {
      return this.selectedClient
        ? this.selectedClient.contract !== null
        : false;
    },

    haveConectaControlProduct() {
      let result = false;
      this.cards.forEach((card) => {
        if (
          card.plan.products.find(
            (product) => product.name === "conecta_control"
          ) &&
          card.status !== "DISABLED"
        ) {
          result = true;
          return;
        }
      });
      return result;
    },

    editInfoDisabled() {
      let currentTags = this.selectedClient.tag ? this.selectedClient.tag : [];
      let diff1 = this.editedTag.filter(
        (value) => !currentTags.includes(value)
      );
      let diff2 = currentTags.filter(
        (value) => !this.editedTag.includes(value)
      );
      let isDisabled = !(Boolean(diff1.length) || Boolean(diff2.length));

      return isDisabled && this.isBillable === this.selectedClient.is_billable;
    },
  },

  methods: {
    formatDate,

    formatDateForDatabase,

    formatDateAndHour,

    updateControlDomainStatus,

    changeClientModuleStatus,

    getTheEndOfTrial,

    ...mapActions([
      "getClients",
      "getCompanyPlanByKey",
      "getSelectedResellerClient",
      "getContractedPlans",
      "getCompanyAddressByCnpj",
      "getPlans",
      "getControlDomainStatus",
    ]),

    ...mapMutations([
      "setSnackBar",
      "setCompany",
      "setSelectedPlan",
      "setSelectedClient",
      "setClientView",
      "setIsHomePage",
      "setExternalControlAccess",
      "setClientToken",
      "setIsResellerPage",
      "setCalendars",
      "setUserById",
      "setOnboardingStep",
      "setUsers",
      "setOffboardingStep",
      "clearOnboardingForms",
      "setOnboardingUser",
      "setOnboardingCreatedUser",
    ]),

    openCancelClientDialog() {
      this.showConfirmCancelClient = true;
    },

    openSuspendClientDialog() {
      this.showConfirmSuspendClient = true;
    },

    openCancelAndSuspendClientDialog() {
      this.showConfirmCancelAndSuspendClient = true;
    },

    clearLocalStorageCompany() {
      localStorage.removeItem("company");
      localStorage.removeItem("allCalendars");
      localStorage.removeItem("users");
      localStorage.removeItem("offboardUser");
      this.clearOnboardingForms();
      this.setOnboardingUser();
      this.setOnboardingCreatedUser();
      this.setUserById({});
      this.setCalendars({});
      this.setUsers([]);
      this.setOnboardingStep();
      this.setOffboardingStep();
    },

    simulateLoginSuite() {
      this.clearLocalStorageCompany();
      const tokenReseller = localStorage.getItem("token");
      this.$router.push(
        `/auth?token=${this.clientToken}&tokenReseller=${tokenReseller}`
      );
    },

    isSelectedPlan(plan) {
      if (plan && this.editedPlan) {
        return plan.key === this.editedPlan.key;
      }
    },

    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },

    async hideShowScrollBar() {
      if (this.showEditConectaPartner) {
        await this.$nextTick(() => {
          document.documentElement.classList.add("overflow-y-hidden");
        });
      }
    },

    reloadMoreInfoPlans() {
      this.editedPlan = null;
      this.editedCompanyPlan = "";
      this.editedCompanyPlanLogo = "";
    },

    handleResize() {
      this.pageHeight = window.innerHeight;
    },

    showSuccessUpdate(message = "success") {
      this.showSuccess = true;
      this.successMessage = message;
    },

    async showSuccessResellerChange(old_reseller_name) {
      this.showSuccess = true;
      this.successMessage = "newReseller";
      this.oldResellerName = old_reseller_name;
      await this.updateClientInfo();
    },

    async showSuccessRemoveReseller(old_reseller_name) {
      this.showSuccess = true;
      this.successMessage = "resellerRemove";
      this.oldResellerName = old_reseller_name;
      await this.updateClientInfo();
    },

    showSuccessUpdateResellerStatus() {
      this.showSuccess = true;
      this.successMessage = "newStatusReseller";
    },

    showFailureResellerChange() {
      this.showError = true;
      this.oldResellerName = this.selectedClient.reseller_name;
      this.errorMessage = "newReseller";
    },

    showFailureRemoveReseller() {
      this.showError = true;
      this.errorMessage = "resellerRemove";
    },

    showFailureUpdateResellerStatus() {
      this.showError = true;
      this.errorMessage = "newStatusReseller";
    },

    showFailureUpdate(message = "failure") {
      this.errorMessage = message;
      this.showError = true;
    },

    showFailureProduct() {
      this.showError = true;
      this.errorMessage = "failureProduct";
    },

    showFailureToEdit() {
      this.showError = true;
      this.errorMessage = "editFailure";
    },

    editInfo() {
      this.loadingEditionStatus = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/${this.selectedClient.key}/edit-info`;
      const config = { headers: { Authorization: this.token } };
      const payload = {
        tag: this.editedTag,
        is_billable: null,
      };

      if (
        this.isBillable !== this.selectedClient.is_billable &&
        this.isConecta
      ) {
        payload["is_billable"] = this.isBillable;
      }

      this.$axios
        .patch(url, payload, config)
        .then(() => {
          this.setSelectedClient({
            ...this.selectedClient,
            is_billable: this.isBillable,
            tag: this.editedTag,
          });
          this.showSuccessUpdate();
        })
        .catch(() => {
          this.showFailureUpdate();
        })
        .finally(() => {
          this.showEditInfoDialog = false;
          this.loadingEditionStatus = false;
        });
    },

    formatPhoneNumber(number) {
      return number.replace("+55", "");
    },

    async newProduct() {
      let key = "";
      await this.getSelectedResellerClient(this.clientKey).then(() => {
        if (this.getSelectedResellerClientError) {
          this.goBack();
          this.setSnackBar({
            show: true,
            message: "Cliente não existe.",
            color: "error",
          });
        }
      });
      key = this.clientKey;
      this.$router.push({
        path: "/reseller/new-plan",
        query: { key: key },
      });
    },

    async updateClientInfo() {
      await this.getSelectedResellerClient(this.clientKey);
    },

    async _checkIfPhoneIsVerified() {
      const phone = this.selectedClient?.verified_phone;
      if (!phone) return;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/${this.selectedClient.key}/phone-is-verified/${phone}`;
      try {
        const response = await this.$axios.get(url, {
          headers: { Authorization: this.token },
        });
        return response.data;
      } catch (err) {
        console.error("_checkIfPhoneIsVerified", err);
      }
    },

    async checkIfPhoneIsVerified() {
      this.loadingIfPhoneIsVerified = true;
      const data = await this._checkIfPhoneIsVerified();
      const clienfInfo = this.generalClientInfo.find(
        (item) => item.key === "verified_phone"
      );
      if (data) {
        clienfInfo.alertIcon = "mdi-shield-check-outline";
        clienfInfo.alertIconColor = "success";
        clienfInfo.alertIconDesc = "Foi validado por SMS";
      } else {
        clienfInfo.alertIcon = "mdi-alert-outline";
        clienfInfo.alertIconColor = "warning";
        clienfInfo.alertIconDesc = "Não foi validado por SMS";
      }
      this.loadingIfPhoneIsVerified = false;
    },

    async startLoadClient(domain = null) {
      this.setClientView(false);
      this.setIsHomePage(false);
      this.mainDomain = domain === null ? this.$route.query.domain : domain;
      await this.getSelectedResellerClient(this.clientKey).then(() => {
        if (this.getSelectedResellerClientError) {
          this.goBack();
        }
      });
      this.getLastSeen();
      await this.getControlDomainStatus(this.mainDomain);
      this.changeIsReseller = this.selectedClient.is_reseller;
      this.conectaPartner =
        Object.assign({}, this.selectedClient.reseller_config) || null;
      this.getClientToken();
      this.editedTag = this.selectedClient.tag;
      this.isBillable = this.selectedClient.is_billable;
      this.loading = false;
      this.cards = this.selectedClient.plans;
      this.showLoadingModules = false;

      if (this.selectedClient.plans) {
        this.hasTrialPlan = this.selectedClient.plans.some(
          (companyPlan) => companyPlan.status === STATUS.TRIAL
        );
        this.hasActivePlan = this.selectedClient.plans.some(
          (companyPlan) => companyPlan.status === STATUS.ACTIVE
        );
      }

      if (this.$route.query.plan) {
        let plan = await this.selectedClient.plans.find(
          (card) => card.key === this.$route.query.plan
        );
        if (plan) {
          await this.selectCompanyPlanToEdit(plan);
          if (this.showModuleInfo) {
            this.scrollMeTo();
          }
        }
      }

      this.updateControlDomainStatus(this.selectedClient.main_domain);
    },

    async requestPhoneVerification() {
      let url =
        process.env.VUE_APP_API_BASE_URL +
        `/customers/${this.clientKey}/phone-reset`;

      this.loadingPhoneVerification = true;

      await this.$axios
        .post(url, {}, { headers: { Authorization: this.token } })
        .then(({ data }) => {
          this.setSnackBar({
            show: true,
            color: "success",
            message: "Telefone resetado com sucesso!",
          });
          this.setSelectedClient(data);
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: "Erro ao resetar telefone do cliente",
          });
        })
        .finally(() => {
          this.loadingPhoneVerification = false;
          this.confirmRemoveVerifiedPhone = false;
        });
    },

    async saveVerifiedPhone() {
      let url =
        process.env.VUE_APP_API_BASE_URL +
        `/customers/${this.clientKey}/phone-reset`;

      this.loadingPhoneVerification = true;

      let payload = {
        phone: this.verifiedPhone,
      };

      await this.$axios
        .post(url, payload, { headers: { Authorization: this.token } })
        .then(({ data }) => {
          this.setSnackBar({
            show: true,
            color: "success",
            message: "Telefone alterado com sucesso!",
          });
          this.setSelectedClient(data);
        })
        .catch(() => {
          this.setSnackBar({
            show: true,
            color: "error",
            message: "Erro ao alterar telefone do cliente",
          });
        })
        .finally(() => {
          this.loadingPhoneVerification = false;
          this.editVerifiedPhoneDialog = false;
          this.verifiedPhone = "";
        });
    },

    goBack() {
      this.$router.push({ path: "/reseller/clients" });
    },

    getClientToken() {
      this.loadingSimulation = true;

      let url = `${process.env.VUE_APP_API_BASE_URL}/customers/${this.selectedClient.key}/token`;

      this.$axios
        .get(url, {
          headers: { Authorization: this.token },
        })
        .then(({ data }) => {
          this.clientToken = data.token;
          this.setClientToken(data.token);
          this.loadingSimulation = false;
        })
        .catch((err) => {
          console.error("loginAsReseller", err);
          this.clientToken = null;
          this.loadingSimulation = false;
        });
    },

    closeEditingCompanyPlanStatus() {
      this.showDialogConfirmStatus = false;
    },

    saveEditingCompanyPlanStatus(change_end_trial) {
      this.showDialogConfirmStatus = false;
      this.putCompanyPlanStatus(this.newStatus, change_end_trial);
    },

    confirmEditCompanyPlanStatus(newStatus) {
      this.showDialogConfirmStatus = true;
      this.newStatus = newStatus;
    },

    putCompanyPlanStatus(new_status, change_end_trial) {
      this.oldStatusEditedPlan = this.editedPlan.status;
      this.editedPlan.status = new_status;

      const payload = {
        status: new_status,
        subscription_type: this.editedPlan.subscription_type,
        create_transaction:
          new_status === "ACTIVE" && this.editedPlan.type === "ANNUAL",
      };

      if (this.editedPlan.subscription_type === "ANNUAL") {
        payload.max_licenses = this.editedPlan.max_licenses;
      }

      if (new_status === "TRIAL" && change_end_trial) {
        payload.end_trial = formatDateForDatabase(getTheEndOfTrial());
      }

      this.putCompanyPlan(payload, this.editedPlan.key, true);
    },

    editTrialDate(newTrialDate) {
      const payload = {
        end_trial: newTrialDate,
      };
      this.putCompanyPlan(payload, this.editedPlan.key);
    },

    async putCompanyPlan(payload, planKey, getPlans = false) {
      this.showLoadingModules = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/company-plan/${planKey}`;

      this.$axios
        .patch(url, payload, {
          headers: { Authorization: this.token },
        })
        .then(async (response) => {
          if (
            this.selectedClient.main_domain === this.company.main_domain ||
            getPlans
          ) {
            await this.getContractedPlans();
          }
          if (payload.end_trial) {
            this.editedPlan.end_trial = payload.end_trial;
            this.editedPlan.valid_status = response.data.valid_status;
          }
          await this.getSelectedResellerClient(this.clientKey);
          this.updateControlDomainStatus(this.selectedClient.main_domain);

          this.showSuccessUpdate();
        })
        .catch((e) => {
          this.editedPlan.status = this.oldStatusEditedPlan;
          this.showFailureProduct();
          console.error("putCompanyPlanStatus(): ", e);
        })
        .finally(() => {
          this.showLoadingModules = false;
        });
    },

    async changeModuleStatus(status, verify_control_status = false) {
      let module = this.cards.filter(
        (product) => product.name === status.module
      );
      let moduleKey = module[0].key;

      const payload = {
        status: status.newStatus.toUpperCase(),
        price: parseFloat(this.price),
      };

      if (
        this.hasControlModuleAtSuite &&
        status.module === "conecta_control" &&
        verify_control_status
      ) {
        this.loadingModuleStatus = true;
        await this.changeClientModuleStatus(moduleKey, payload)
          .then(({ data }) => {
            this.$store.dispatch("getContractedProducts");
            this.editedModuleStatus = data.status.toLowerCase();
          })
          .catch(() => {
            console.error("changeClientModuleStatus()");
            this.loadingModuleStatus = false;
          });
        this.loadingModuleStatus = false;
      } else if (
        !this.hasControlModuleAtSuite &&
        status.module === "conecta_control"
      ) {
        this.loadingModuleStatus = true;
        this.cards.find((product) => {
          product.name === status.module
            ? (product.status = status.newStatus)
            : false;
        });
        this.editedModuleStatus = status.newStatus;
        this.loadingModuleStatus = false;
      } else {
        this.loadingModuleStatus = true;
        await this.changeClientModuleStatus(moduleKey, payload)
          .then(({ data }) => {
            this.$store.dispatch("getContractedProducts");
            this.editedModuleStatus = data.status.toLowerCase();
          })
          .catch(() => {
            console.error("changeClientModuleStatus()");
            this.loadingModuleStatus = false;
          });
        this.loadingModuleStatus = false;
      }
    },

    selectCompanyPlanToEdit(company_plan) {
      this.editedCompanyPlan = company_plan.plan.name;
      this.editedCompanyPlanLogo = company_plan.plan
        ? company_plan.plan.short_code
        : "plans";
      this.editedPlan = company_plan;
      this.editedPlan.valid_status = company_plan.valid_status;
      this.editedPlan.next_billing = company_plan.next_billing;
      this.editedPlan.status = company_plan.status;
      this.editedPlan.type = company_plan.subscription_type;
      this.editedPlan.licenses = this.selectedClient.users_number;
      this.editedPlan.price = company_plan.price;
      this.editedPlan.init_date = company_plan.init_date;
      this.editedPlan.init_billing = company_plan.init_billing;

      this.editedPlan.products = company_plan.plan.products;

      this.editedPlan.products = this.editedPlan.products.map(
        (elm) => productNames[elm.name]
      );
    },

    async goToPlanDetails(plan) {
      this.showConfirmationDialog = false;
      this.selectedCompanyPlan = {};
      if (Object.keys(plan).length) {
        await this.selectCompanyPlanToEdit(plan);
        if (this.showModuleInfo) {
          this.scrollMeTo();
        }
      } else {
        this.newProduct();
      }
    },

    confirmEditDialog(plan = {}) {
      if (Object.keys(plan).length > 0) {
        this.setSelectedPlan(plan.plan_key);
      }
      if (!this.checkResellerPermission()) {
        this.isConecta
          ? (this.showConfirmationDialog = true)
          : this.showFailureToEdit();
        this.selectedCompanyPlan = plan;
      } else {
        this.goToPlanDetails(plan);
      }
    },

    createNewService() {
      this.$router.push({
        path: "/reseller/new-service",
        query: { key: this.clientKey },
      });
    },

    scrollMeTo() {
      var divElem = document.getElementById("client");
      var chElem = document.getElementById("signatures");
      var topPos = divElem.offsetTop;
      divElem.scrollTo({
        left: 0,
        top: parseFloat(topPos + chElem.offsetTop + 100),
        behavior: "smooth",
      });
    },

    checkResellerPermission() {
      if (this.selectedClient) {
        return this.selectedClient.reseller_token === this.company.cs_token
          ? true
          : false;
      }
    },

    resetEditedInfo() {
      this.showEditInfoDialog = false;
      this.editedTag = this.selectedClient.tag;
      this.isBillable = this.selectedClient.is_billable;
    },

    syncCompanyAddrees(cnpj) {
      this.loadingAddressUpdate = true;
      this.getCompanyAddressByCnpj(cnpj)
        .then(({ data }) => {
          const { cep, logradouro, numero, complemento, municipio, uf, nome } =
            data;
          const payload = {
            address_postal_code: cep,
            address_info: logradouro,
            address_number: numero,
            address_complement: complemento,
            address_city: municipio,
            address_state: uf,
            corporate_name: nome,
          };
          this.updateCompanyAddress(payload);
        })
        .catch((error) => {
          const { data } = error.response;
          this.showFailureUpdate(data.code);
          this.loadingAddressUpdate = false;
        });
    },

    updateCompanyAddress(payload) {
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/${this.selectedClient.key}/address`;

      const config = {
        headers: {
          Authorization: this.token,
        },
      };

      this.$axios
        .put(url, payload, config)
        .then((response) => {
          const company = response.data;
          this.setSelectedClient(company);
          this.showSuccessUpdate();
        })
        .catch(() => {
          this.showFailureUpdate();
        })
        .finally(() => {
          this.loadingAddressUpdate = false;
        });
    },

    async cancelClient() {
      this.loadingClientStatus = true;
      const tokenReseller = localStorage.getItem("token");
      const key = this.selectedClient.key;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/cancellation/${key}`;
      const token = `Bearer ${tokenReseller}`;

      const payload = {
        reason: this.cancelationReason,
      };

      await this.$axios
        .patch(url, payload, { headers: { Authorization: token } })
        .then(async () => {
          await this.startLoadClient();
          this.editedPlan = null;
          this.showSuccessUpdate();
          this.showConfirmCancelClient = false;
          this.cancelationReason = "";
        })
        .catch(() => {
          this.showFailureUpdate();
        })
        .finally(() => {
          this.loadingClientStatus = false;
        });
    },

    async suspendClient() {
      this.loadingClientStatus = true;
      const { key } = this.selectedClient;

      const headers = { headers: { Authorization: this.token } };
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/suspend/${key}`;

      const payload = {
        reason: this.suspensionReason,
      };

      await this.$axios
        .patch(url, payload, headers)
        .then(async () => {
          await this.startLoadClient();
          this.editedPlan = null;
          this.showSuccessUpdate();
          this.showConfirmSuspendClient = false;
          this.suspensionReason = "";
        })
        .catch(() => {
          this.showFailureUpdate();
        })
        .finally(() => {
          this.loadingClientStatus = false;
        });
    },

    async unsuspendClient() {
      this.loadingClientStatus = true;
      const { key } = this.selectedClient;

      const headers = { headers: { Authorization: this.token } };
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/remove-suspension/${key}`;
      const payload = {
        reason: this.suspensionReason,
      };

      await this.$axios
        .patch(url, payload, headers)
        .then(async () => {
          await this.startLoadClient();
          this.editedPlan = null;
          this.showSuccessUpdate();
          this.showConfirmSuspendClient = false;
          this.suspensionReason = "";
        })
        .catch(() => {
          this.showFailureUpdate();
        })
        .finally(() => {
          this.loadingClientStatus = false;
        });
    },

    async cancelAndSuspendClient() {
      this.loadingClientStatus = true;
      const { key } = this.selectedClient;

      const headers = { headers: { Authorization: this.token } };
      const url = `${process.env.VUE_APP_API_BASE_URL}/clients/cancel-and-suspend/${key}`;

      const payload = {
        reason: this.cancelAndSuspendReason,
      };

      await this.$axios
        .patch(url, payload, headers)
        .then(async () => {
          await this.startLoadClient();
          this.editedPlan = null;
          this.showSuccessUpdate();
          this.showConfirmCancelAndSuspendClient = false;
          this.cancelAndSuspendReason = "";
        })
        .catch(() => {
          this.showFailureUpdate();
        })
        .finally(() => {
          this.loadingClientStatus = false;
        });
    },

    getLastSeen() {
      this.loadingLastSeen = true;
      const key = this.selectedClient.key;
      const url = `${process.env.VUE_APP_API_BASE_URL}/company/${key}/last-seen`;
      this.$axios
        .get(url, { headers: { Authorization: this.token } })
        .then(({ data }) => {
          this.lastSeen.suite = data.suite_last_seen_date;
          this.lastSeen.control = data.control_last_seen_date;
        })
        .catch((error) => {
          this.setSnackBar({
            message:
              "Erro ao obter informações da empresa sobre o uso das ferramentas",
            show: true,
            color: "error",
          });
          console.error("getLastSeen()", error);
        })
        .finally(() => {
          this.loadingLastSeen = false;
        });
    },
  },

  watch: {
    showSuccess() {
      if (!this.showSuccess) {
        this.oldResellerName = null;
      }
    },

    showError() {
      if (!this.showError) {
        this.oldResellerName = null;
      }
    },

    async "$route.fullPath"() {
      this.loading = true;
      await this.startLoadClient();
      await this.getContractedPlans();
    },

    showEditConectaPartner(new_value) {
      if (new_value) {
        this.hideShowScrollBar();
      }
    },
  },

  async beforeMount() {
    this.loading = true;
    this.getPlans();
    this.setIsResellerPage(true);
    await this.startLoadClient();
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (!this.clients.length) {
      this.getClients();
    }
  },

  destroyed() {
    this.setIsResellerPage(false);
    window.removeEventListener("resize", this.handleResize);
    this.setExternalControlAccess(null);
  },
};
</script>

<style scoped>
.selected-card {
  box-shadow: inset 0 -4px 0 #1a73e8 !important;
}

.v-breadcrumbs--large li {
  color: #202124 !important;
  font-size: 24px !important;
}

.v-breadcrumbs li:hover,
.v-breadcrumbs--large li:hover {
  color: var(--v-info-darken1);
}

.disable-btn {
  opacity: 0.5;
  cursor: not-allowed;
}

.horizontal-scroll {
  max-width: 350px;
  overflow-x: auto;
  white-space: nowrap;
}
</style>
